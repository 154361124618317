<template>
  <div class="app-container">
    <div class="top_box">
      <div>
        <el-form :inline="true" class="demo-form-inline account-top">
          <el-form-item>
            <el-input
              v-model="phone"
              placeholder="请输入手机号"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              @click="addTask"
              size="mini"
              type="success"
              v-loading="listLoading"
            >
              添加方案
            </el-button>
          </el-form-item>

          <el-form-item>
            <el-button
              size="mini"
              type="primary"
              @click="fetchData"
              v-loading="listLoading"
            >
              刷新
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="bottom_box">
      <div class="content_t">
        <el-table
          v-loading="listLoading"
          :data="list"
          element-loading-text="Loading"
          border
          fit
          highlight-current-row
          class="tableList2"
          :header-cell-style="{ background: '#F3F7F9', color: '#909399' }"
          height="100%"
          style="width: 100%"
          algin="center"
          :default-sort="{ prop: 'create_time', order: 'descending' }"
          @sort-change="handleSort"
        >
          <el-table-column prop="id" label="ID" width="100"></el-table-column>
          <el-table-column prop="phone" label="手机号"></el-table-column>
          <el-table-column prop="user_id" label="用户ID"></el-table-column>
          <el-table-column prop="state" label="任务状态">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                :content="getStatusTip(scope.row.state, statusMap)"
                placement="top"
              >
                <el-tag
                  :type="getStatusType(scope.row.state, statusMap)"
                  disable-transitions
                >
                  {{ getStatusLabel(scope.row.state, statusMap) }}
                </el-tag>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="user.id" label="用户ID"></el-table-column>
          <el-table-column prop="user.name" label="用户名称"></el-table-column>
          <el-table-column prop="owner" label="查询者" v-if="userInfo.is_staff">
            <template slot-scope="scope">
              {{
                scope.row.owner
                  ? scope.row.owner.oauth_account
                    ? scope.row.owner.oauth_account
                    : scope.row.owner.username
                  : null
              }}
            </template>
          </el-table-column>
          <el-table-column label="查询者备注" v-if="userInfo.is_staff">
            <template slot-scope="scope">
              {{ scope.row.owner ? scope.row.owner.note : null }}
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            prop="create_time"
            sortable="custom"
          ></el-table-column>
        </el-table>
      </div>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryForm.page_index"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryForm.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getPhoneTask, addPhoneTask } from '@/api/detect'
import { getManagerStatus } from '@/utils/manager'
import { getStatusType, getStatusLabel, getStatusTip } from '@/utils/status'
import { cacheMixin } from '@/mixins/mixin'
export default {
  name: 'detectPhone',
  mixins: [cacheMixin],
  components: {},
  data() {
    return {
      userInfo: this.$store.state.user.userInfo,
      list: null,
      listLoading: true,
      count: 0,
      isManager: getManagerStatus(),
      queryForm: {
        page_index: 1,
        page_size: 10,
        search: '',
        ordering: '-create_time'
      },
      phone: null,
      showDialog: false,
      statusMap: {
        2: {
          type: 'info',
          label: '无结果'
        },
        1: {
          type: 'success',
          label: '查询成功'
        },
        0: {
          type: 'primary',
          label: '待执行'
        },
        '-1': {
          type: 'danger',
          label: '执行失败'
        }
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    getStatusType,
    getStatusLabel,
    getStatusTip,
    async fetchData() {
      this.listLoading = true
      const { count, items } = await getPhoneTask(this.queryForm)
      this.count = count
      this.list = items
      this.listLoading = false
    },
    async handleSearch() {
      this.queryForm.page_index = 1
      await this.fetchData()
    },
    handleSizeChange(pageSize) {
      this.queryForm.page_size = pageSize
      // 分页变更时处理
      this.fetchData()
    },
    handleCurrentChange(pageIndex) {
      this.queryForm.page_index = pageIndex
      // 分页变更时处理
      this.fetchData()
    },
    async addTask() {
      this.listLoading = true
      try {
        await addPhoneTask({
          phone: this.phone
        })
        this.fetchData()
      } finally {
        this.phone = null
        this.listLoading = false
      }
    },
    receiveVal(val) {
      this.showDialog = val
      this.fetchData()
    },
    handleSort({ column, prop, order }) {
      if (order === null) {
        this.queryForm.ordering = null
      } else if (order === 'descending') {
        this.queryForm.ordering = '-' + prop
      } else {
        this.queryForm.ordering = prop
      }
      console.log(column, prop, order)
      this.fetchData()
    }
    // // 删除当前方案
    // handleDelete(id) {
    //   this.$confirm('此操作将永久删除该方案, 是否继续?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   })
    //     .then(async () => {
    //       await deletePlan(id)

    //       // this.$message({
    //       //   type: 'success',
    //       //   message: '删除成功!'
    //       // })
    //       this.fetchData()
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: 'info',
    //         message: '已取消删除'
    //       })
    //     })
    // }
  }
}
</script>

<style lang="scss" scoped>
.top_box {
  width: 100%;
  padding: 10px;
  background: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  button.el-button.el-button--primary {
    height: 32px;
    padding: 0 20px;
    // line-height: 32px;
  }
  .el-form-item {
    margin-bottom: 0;
    .el-input {
      padding: 0 15px;
      width: 250px;
      ::v-deep span.el-input__suffix {
        right: 20px;
      }
    }
    ::v-deep input.el-input__inner {
      height: 32px;
      line-height: 32px;
    }
  }
}

.bottom_box {
  width: 100%;
  height: calc(100vh - 178px);
  margin-top: 10px;
  border: 1px solid #e5e5e5;

  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  padding: 10px;
  .content_t {
    height: calc(100% - 50px);
    margin-top: 10px;
    // overflow: auto;
    ::v-deep td.el-table_1_column_1.el-table__cell {
      text-align: center;
    }
  }
}
</style>
